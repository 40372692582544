import {createBrowserRouter} from "react-router-dom";
import React from "react";
import App from "./App.jsx";
import Home from "./pages/Site/Home.jsx";
import Panel from "./pages/Site/Panel.jsx";
import Ranking from "./pages/Site/Ranking.jsx";
import Shop from "./pages/Site/Shop.jsx";
import News from "./pages/Site/News.jsx";
import Post from "./pages/Site/Post.jsx";
import Register from "./pages/Site/Register.jsx";
import Download from "./pages/Site/Download.jsx";
import Checkout from "./pages/Site/Checkout.jsx";
import Overview from "./pages/Admin/Tabs/Overview.jsx";
import NewsManagement from "./pages/Admin/Tabs/NewsManagement.jsx";
import GuildmarksManagement from "./pages/Admin/Tabs/GuildmarksManagement.jsx";
import PurchasesHistoryManagement from "./pages/Admin/Tabs/PurchasesHistoryManagement.jsx";
import ShopManagement from "./pages/Admin/Tabs/ShopManagement.jsx";
import AccountSettingsManagement from "./pages/Admin/Tabs/AccountSettingsManagement.jsx";
import HomeAdmin from "./pages/Admin/HomeAdmin.jsx";

const classicBrowserRouter = createBrowserRouter(
    [
        {
            Element: <App/>,
            children: [
                {
                    path: "/",
                    element: <Home/>
                },
                {
                    path: "/panel",
                    element: <Panel/>
                },
                {
                    path: "/ranking",
                    element: <Ranking/>
                },
                {
                    path: "/shop",
                    element: <Shop/>
                },
                {
                    path: "/news",
                    element: <News/>
                },
                {
                    path: "/post/:postId",
                    element: <Post/>
                },
                {
                    path: "/register",
                    element: <Register/>
                },
                {
                    path: "/download",
                    element: <Download/>
                },
                {
                    path: "/ranking",
                    element: <Ranking/>
                },
                {
                    path: "/checkout",
                    element: <Checkout/>
                },

                // admin routes
                {
                    path: "/admin/",
                    element: <HomeAdmin/>
                },
                {
                    path: "/admin/panel",
                    element: <Overview/>
                },
                {
                    path: "/admin/overview",
                    element: <Overview/>
                },
                {
                    path: "/admin/news",
                    element: <NewsManagement/>
                },
                {
                    path: "/admin/guildmarks",
                    element: <GuildmarksManagement/>
                },
                {
                    path: "/admin/purchases",
                    element: <PurchasesHistoryManagement/>
                },
                {
                    path: "/admin/shop",
                    element: <ShopManagement/>
                },
                {
                    path: "/admin/settings",
                    element: <AccountSettingsManagement/>
                }

            ]
        }
    ]
);



export default classicBrowserRouter;