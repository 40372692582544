import {useEffect, useState} from "react";
import SessionUtils from "../../../utils/SessionUtils.jsx";

export default function DefaultLoader() {

    useEffect(() => {
        SessionUtils.getSiteData((error, data) => {
            if (!error) {
                document.title = data.serverFantasyName;
                document.getElementById("favicon").href = data.favIconImage;
                document.body.style.backgroundImage = `url(${data.backgroundImage})`;
            } else {
                console.error('Failed to load site data:', error);
            }
        });
    }, []);

    return <></>
}