import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './languages/en.json';
import ptTranslations from './languages/pt.json';
import phiTranslations from './languages/phi.json';
import esTranslations from './languages/es.json';


i18n.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslations },
        pt: { translation: ptTranslations },
        es: { translation: esTranslations },
        phi: { translation: phiTranslations }
    },
    lng: localStorage.getItem('lang') || 'pt',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
