import axios from 'axios';
import Environment from './Environment.jsx';
import Dexie from 'dexie';

const db = new Dexie('ServerCustomerDB');

db.version(1).stores({
    serverCustomer: 'id, user, password, isActive, email, databaseName, serverIpv4Address, serverIpv6Address, sshKey, domainFrontEnd, domainIntegration, serverFantasyName, releaseDate, enableReleaseDateCounter, releaseMessage, favIconImage, releaseImage, launcherImage, logoImage, backgroundImage, homePageText, homePageTitle, footerText, discordUrl, facebookUrl, youtubeUrl, whatsappUrl, discordWebhookNotifications, lastUpdated'
});

class SessionUtils {
    loadSiteData() {
        return db.serverCustomer.get('serverData')
            .then(data => {
                const currentTime = new Date().getTime();
                const shouldUpdate = !data || !data.lastUpdated || (currentTime - data.lastUpdated > 60000); // 1 minuto em milissegundos

                if (data && !shouldUpdate) {
                    return data;
                }

                return axios.get(`${Environment.getBaseUrl()}/public/configuracoes`)
                    .then(response => {
                        const fetchedData = response.data;
                        const updatedData = { id: 'serverData', ...fetchedData, lastUpdated: currentTime };
                        return db.serverCustomer.put(updatedData)
                            .then(() => updatedData);
                    })
                    .catch(error => {
                        console.error('Failed to fetch site data from API', error);
                        throw error;
                    });
            })
            .catch(error => {
                console.error('Failed to fetch site data from IndexedDB', error);
                throw error;
            });
    }

    getSiteData(callback) {
        this.loadSiteData()
            .then(data => {
                callback(null, data);
            })
            .catch(error => {
                callback(error, null);
            });
    }
}

export default new SessionUtils();
