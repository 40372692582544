import React, { useEffect, useState } from 'react';
import MenuNavbar from "../../components/site/MenuNavbar/MenuNavbar.jsx";
import Footer from "../../components/site/Footer/Footer.jsx";
import MiniRankingList from "../../components/site/MiniRankingList/MiniRankingList.jsx";
import SessionUtils from "../../utils/SessionUtils.jsx";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import DefaultLoader from "../../components/site/DefaultLoader/DefaultLoader.jsx";
import Loading from "../../components/site/Loading/Loading.jsx";

const Home = () => {
    const [siteData, setSiteData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        SessionUtils.getSiteData((error, data) => {
            if (!error) {
                setSiteData(data);
            } else {
                console.error('Failed to load site data:', error);
            }
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className='h-screen overflow-y-auto w-full'>
            <DefaultLoader />

            <MenuNavbar />
            <div className='relative text-center text-sm w-full'>
                <div className='mr-[2vw]'>
                    <MiniRankingList />
                </div>
                <div className="mt-4 ml-[2vw] z-9999">
                    <iframe
                        src="https://discord.com/widget?id=829109662443241513&theme=dark"
                        width="350"
                        height="350"
                        allowTransparency="true"
                        frameBorder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                    ></iframe>
                </div>
                {siteData && (
                    <FloatingWhatsApp
                        avatar={siteData.logoImage}
                        accountName={siteData.serverFantasyName}
                        onSubmit={() => {
                            window.location.href = siteData.whatsappUrl;
                        }}
                        darkMode={true}
                        chatMessage={`Seja bem vindo ao ${siteData.serverFantasyName || ""}, nos envie uma mensagem para entrar no grupo.`}
                        statusMessage="Estamos Online"
                        phoneNumber=""
                        buttonStyle={{
                            marginBottom: '10vh',
                            marginRight: '2vw'
                        }}
                    />
                )}
            </div>
            <div className='text-center hidden md:block homepagetitle mt-[4vh]'>
                <h1 id="homePageTitle">{siteData?.homePageTitle}</h1>
            </div>
            <div className="container mx-auto h-5/6 paper mb-48 text-xl overflow-y-auto">
                {siteData && (
                    <div
                        className="text-black p-24"
                        id="homePageText"
                        dangerouslySetInnerHTML={{ __html: siteData.homePageText }}
                    />
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Home;
