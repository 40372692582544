import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Panel from './pages/Site/Panel.jsx';
import Ranking from './pages/Site/Ranking.jsx';
import Shop from './pages/Site/Shop.jsx';
import News from './pages/Site/News.jsx';
import Post from './pages/Site/Post.jsx';
import './index.css';
import Register from './pages/Site/Register.jsx';
import Download from "./pages/Site/Download.jsx";
import Checkout from "./pages/Site/Checkout.jsx";
import Home from "./pages/Site/Home.jsx";
import HomeAdmin from "./pages/Admin/HomeAdmin.jsx";
import Overview from "./pages/Admin/Tabs/Overview.jsx";
import NewsManagement from "./pages/Admin/Tabs/NewsManagement.jsx";
import GuildmarksManagement from "./pages/Admin/Tabs/GuildmarksManagement.jsx";
import PurchasesHistoryManagement from "./pages/Admin/Tabs/PurchasesHistoryManagement.jsx";
import AccountSettingsManagement from "./pages/Admin/Tabs/AccountSettingsManagement.jsx";
import axios from "axios";
import Environment from "./utils/Environment.jsx";
import ShopManagement from "./pages/Admin/Tabs/ShopManagement.jsx";
import Swal from "sweetalert2";
import './utils/i18n/i18n.jsx'
import * as Sentry from "@sentry/react";
import SessionUtils from "./utils/SessionUtils.jsx";
import classicBrowserRouter from "./ClassicBrowserRouter.jsx";

const initApplication = () => {
    SessionUtils.loadSiteData();
    Sentry.init({
        dsn: "https://d7ef410e1e5cee2c3ed3c0414fbed5f4@o4507809598865408.ingest.de.sentry.io/4507809600372816",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", "warriors.gamecms.com.br/api"],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

initApplication();

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <RouterProvider router={classicBrowserRouter}/>
    </React.StrictMode>,
);
