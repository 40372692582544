
const recaptchakey = "6LcciB0pAAAAAPoOurQcrJYb_1mLxaHdE69DToC-";
const baseCurrency = {
    "sign" : "R$",
    "name" : "BRL"
}

const profiles = {
    production : {
        backendCentralizado : `https://warriors.gamecms.com.br/api/v1`,
        integracaoUrl: `https://warriors.gamecms.com.br/integracao/wyd/api/v1`,
        recaptchakey : recaptchakey,
        currency : baseCurrency
    },
    development : {
        backendCentralizado : `http://localhost/api/v1`,
        integracaoUrl: `http://localhost/integracao/wyd/api/v1`,
        recaptchakey : recaptchakey,
        currency : baseCurrency
    }
};

class Environment {

    profile = window.location.href.includes('gamecms') ? profiles.production : profiles.development;


    baseUrl = this.profile.backendCentralizado;
    integracaoUrl = this.profile.integracaoUrl;
    recaptchakey = this.profile.recaptchakey;
    profiles = profiles;
    currency = this.profile.currency;

    getBaseUrl = () => this.baseUrl;

    getRecaptchaPublicKey = () => this.recaptchakey;

    getIntegracaoUrl = () => this.integracaoUrl;

    getProfiles = () => this.profiles;

    getCurrency = () => this.currency;
}

const _Environment = new Environment();



export default _Environment;

